export const platforms = [
  {
    label: 'iOS',
    value: 1
  },
  {
    label: 'android',
    value: 2
  }
]

export const platformFormat = (data) => {
  const found = platforms.find(type => type.value === data.platform)
  if (found) {
    return found.label
  }
  return data.platform
}

export const productTypes = [
  {
    label: 'VIP',
    value: 1
  },
  {
    label: '充值',
    value: 2
  }
]

export const productTypeFormat = (product) => {
  const found = productTypes.find(type => type.value === product.type)
  if (found) {
    return found.label
  }
  return product.type
}

export const priceFormat = (price) => {
  if (price) {
    return price / 100
  }
  return '-'
}

export const recordStatusOpts = [
  {
    value: 0,
    label: '已支付'
  },
  {
    value: 1,
    label: '取消'
  },
  {
    value: 2,
    label: '等待'
  }
]
export const recordCancelReasons = [
  {
    value: 0,
    label: '用户主动'
  },
  {
    value: 1,
    label: '系统取消'
  },
  {
    value: 2,
    label: '替换新订阅'
  },
  {
    value: 3,
    label: '开发者取消'
  }
]

export const recordStatusFormat = (record) => {
  if (record.productInAppType === 2) {
    return recordStatusOpts.find(item => item.value === record.purchaseState).label
  }
  if (record.productInAppType === 1) {
    if (record.cancelAt || record.cancelReason) {
      return '取消（' + recordCancelReasons.find(item => item.value === record.cancelReason).label + '）'
    } else {
      return '续订中'
    }
  }

  return '类型错误'
}
