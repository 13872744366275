<template>
  <div class='HeaderMenuView-container'>
    <el-menu
        :default-active="selectedTopMenuCode"
        mode="horizontal"
        @select="changeTopMenu"
        ref="menuSelector">
      <template v-for="(menu,i) in topMenus" :key="i">
        <el-sub-menu v-if="menu.children" :index="i+''">
          <template #title>
            <span>{{ menu.name }}</span>
          </template>
          <template v-for="(item,j) in menu.children" :key="j">
            <el-sub-menu v-if="item.children" :index="i+'_'+j">
              <template #title>
                <span>{{ item.name }}</span>
              </template>
              <template v-for="(subItem,j) in item.children" :key="j">
                <el-sub-menu v-if="subItem.children" :index="i+'_'+j">
                  <template #title>
                    <span>{{ subItem.name }}</span>
                  </template>
                  <el-menu-item :index="sub2Item.code" v-for="(sub2Item,k) in subItem.children" :key="k">{{
                      sub2Item.name
                    }}
                  </el-menu-item>
                </el-sub-menu>
                <el-menu-item :index="subItem.code" v-else>{{ subItem.name }}</el-menu-item>
              </template>
            </el-sub-menu>
            <el-menu-item :index="item.code" v-else>{{ item.name }}</el-menu-item>
          </template>
        </el-sub-menu>
        <el-menu-item :index="menu.code" v-else>{{ menu.name }}</el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'HeaderMenuView',
  data () {
    return {
      active: 'operation'
    }
  },
  computed: {
    ...mapGetters(['permissions', 'mine', 'topMenus']),
    ...mapState(['selectedTopMenuCode'])
  },
  watch: {
    '$route' () {
      this.initTopMenu(this.$route.fullPath)
    }
  },
  created () {
    this.initTopMenu(this.$route.fullPath)
  },
  methods: {
    ...mapActions(['changeTopMenu', 'initTopMenu']),
    onSelect (a) {
      console.log(a)
    }
  }
}
</script>

<style scoped lang='less'>
.HeaderMenuView-container {
  width: 100%;

  .el-menu.el-menu--horizontal {
    background-color: transparent;
    border-bottom: none;

    .el-menu-item {
      color: #fff;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      &:focus {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
}
</style>
