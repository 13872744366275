<template>
  <div class='Pagination-container'>
    <el-pagination background layout="prev, pager, next" :current-page="currentPage"
                   :page-size="20"
                   @current-change="handlePageChange" :total="total"/>
    <div class="f1 m-l-15">共计：{{ total }}</div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PaginationView',
  props: ['currentPage', 'total'],
  data () {
    return {}
  },
  methods: {
    handlePageChange (page) {
      this.$emit('current-change', page)
    }
  }
}
</script>

<style scoped lang='less'>
.Pagination-container {
  background-color: #fff;
  padding: 10px 0;
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  align-items: center;
}
</style>
