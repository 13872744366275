<template>
  <el-card shadow="never" header="用户管理">
    <div class="m-b-15 flex h vc">
      <div style="width: 380px">
        <el-date-picker
          v-model="selectDate"
          type="daterange"
          range-separator="To"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          :shortcuts="shortcuts"
        />
      </div>
      <el-select placeholder="客户端" v-model="searchUniPlatform" style="width: 100px" clearable>
        <el-option :value="item.value" :label="item.label" v-for="item in uniPlatforms" :key="item"></el-option>
      </el-select>
      <el-select placeholder="系统" v-model="searchOsName" style="width: 100px" class="m-l-15" clearable>
        <el-option :value="item.value" :label="item.label" v-for="item in osNames" :key="item"></el-option>
      </el-select>
      <el-input v-model="userId" class="m-l-15" clearable style="width: 100px" placeholder="用户id"></el-input>
      <el-input v-model="tmpSearchKWs" class="m-l-15" clearable style="width: 300px" placeholder="搜索"></el-input>
      <el-button type="primary" style="margin-left: 15px" @click="handleSearch">查询</el-button>
    </div>
    <div class="flex h vc">
      <el-select placeholder="App" v-model="searchApp" style="width: 200px" clearable>
        <el-option :value="app.appId" :label="app.typeStr + ' - ' + app.appName" v-for="app in apps"
                   :key="app.appId"></el-option>
      </el-select>
      <el-input v-model="searchAppChannel" class="m-l-15" clearable style="width: 250px"
                placeholder="渠道号"></el-input>
      <el-input v-model="searchAdId" class="m-l-15" clearable style="width: 100px"
                placeholder="广告id"></el-input>
      <el-select v-model="activeType" placeholder="最近活跃" class="m-l-15" clearable style="width: 100px">
        <el-option :value="1" label="今天"></el-option>
        <el-option :value="2" label="昨天"></el-option>
        <el-option :value="3" label="前天"></el-option>
      </el-select>
      <el-select v-model="searchVip" placeholder="是否VIP" class="m-l-15" clearable style="width: 100px">
        <el-option :value="1" label="是"></el-option>
        <el-option :value="2" label="否"></el-option>
      </el-select>
      <el-select placeholder="奖励" v-model="rewardType" style="width: 100px" class="m-l-15" clearable>
        <el-option :value="1" label="管理员奖励"></el-option>
      </el-select>
    </div>
  </el-card>
  <PaginationView :current-page="currentPage" @current-change="handlePageChange"
                  :total="selectedCount"></PaginationView>
  <el-table :data="list" stripe border>
    <el-table-column type="index" width="50"></el-table-column>
    <el-table-column prop="appChannel" label="渠道包">
      <template #default="{row}">
        <div>{{ channelFormat(row) }}</div>
        <div>{{ adFormat(row) }}</div>
      </template>
    </el-table-column>
    <el-table-column prop="name" label="IP归属" width="100">
      <template #default="{row}">
        {{ row.address || '' }}
      </template>
    </el-table-column>
    <el-table-column prop="userId" label="id" width="100"></el-table-column>
    <el-table-column prop="nicknameStr" label="姓名" width="100">
      <template #default="{row}">
        <router-link :to="{name:'manage_user_detail', params:{id:row.userId}}" target="_blank">
          {{ row.name ? row.name : '未知'}}
        </router-link>
      </template>
    </el-table-column>
    <el-table-column prop="loginCount" label="登录" width="70"></el-table-column>
    <el-table-column prop="loginCount" label="记账统计" width="120">
      <template #default="{row}">
        <!--        <div>记账总数：{{ row.bookingCount || 0}}</div>-->
        <div>手动记账：{{ row.commonBookingCount || 0}}</div>
        <div>AI记账：{{ row.aiBookingCount || 0}}</div>
        <div>定时记账：{{ row.regularBookingCount || 0}}</div>
        <div>资产账户：{{ row.assetCount || 0}}</div>
      </template>
    </el-table-column>
    <el-table-column prop="orders.length" label="订单" width="70"></el-table-column>
    <el-table-column prop="appVersionCode" label="版本" width="80"></el-table-column>
    <el-table-column prop="uniPlatform" label="客户端" width="100">
      <template #default="{row}">
        <div>{{ row.appName }}</div>
        <div>{{ uniPlatformFormat(row) }}-{{ row.osName }}</div>
      </template>
    </el-table-column>
    <el-table-column label="品牌" width="100">
      <template #default="{row}">
        <div>{{ row.deviceBrand }}</div>
        <div style="white-space: nowrap">{{ row.deviceModel }}</div>
      </template>
    </el-table-column>
    <el-table-column label="注册时间" width="100">
      <template #default="{row}">
        {{ dateFormat(row.created) }}
      </template>
    </el-table-column>
    <el-table-column label="最后活跃" width="100">
      <template #default="{row}">
        {{ dateFormat(row.loginAt) }}
      </template>
    </el-table-column>
    <el-table-column label="会员" width="80">
      <template #default="{row}">
        {{ row.vip ? '是' : '否' }}
      </template>
    </el-table-column>
    <el-table-column label="会员来源" width="100">
      <template #default="{row}">
        {{ row.vipFromStr }}
      </template>
    </el-table-column>
    <el-table-column prop="status" label="状态" width="80">
      <template #default="{row}">
        {{ statusFormat(row) }}
      </template>
    </el-table-column>
    <el-table-column label="操作" width="80">
      <template #default="{row}">
        <router-link :to="{name:'manage_user_detail', params:{id:row.userId}}" target="_blank">
          <el-button size="small">详情</el-button>
        </router-link>
      </template>
    </el-table-column>
  </el-table>
  <PaginationView :current-page="currentPage" @current-change="handlePageChange"
                  :total="selectedCount"></PaginationView>
</template>

<script>
  import {userApi, appApi, attributionApi} from '@/api/admin.js'
  import moment from 'moment'

  export default {
    name: 'UserManager',
    data() {
      return {
        login: null,
        selectDate: null,
        selectedCityCode: null,
        shortcuts: [
          {
            text: '今天',
            value: () => {
              const today = moment().format('YYYYMMDD')
              return [today, today]
            }
          },
          {
            text: '昨天',
            value: () => {
              const tomorrow = moment().subtract(1, 'day').format('YYYYMMDD')
              return [tomorrow, tomorrow]
            }
          },
          {
            text: '近7天',
            value: () => {
              const start = moment().subtract(6, 'day').format('YYYYMMDD')
              const today = moment().format('YYYYMMDD')
              return [start, today]
            }
          },
          {
            text: '近30天',
            value: () => {
              const start = moment().subtract(29, 'day').format('YYYYMMDD')
              const today = moment().format('YYYYMMDD')
              return [start, today]
            }
          },
          {
            text: '本月',
            value: () => {
              let start = moment().date(1)
              start = start.format('YYYYMMDD')
              const today = moment().format('YYYYMMDD')
              return [start, today]
            }
          },
          {
            text: '本周',
            value: () => {
              let start = moment().day(1)
              start = start.format('YYYYMMDD')
              const today = moment().format('YYYYMMDD')
              return [start, today]
            }
          }
        ],
        list: [],
        roles: [],
        showForm: false,
        form: {},
        currentPage: 1,
        selectedCount: null,
        tmpSearchKWs: null,
        statusOpts: [
          {
            value: 0,
            label: '停止'
          },
          {
            value: 1,
            label: '正常'
          }
        ],
        uniPlatforms: [
          {
            value: 'mp-weixin',
            label: '微信'
          },
          {
            value: 'app',
            label: 'App'
          }
        ],
        searchUniPlatform: null,
        searchOsName: null,
        osNames: [
          {
            value: 'ios',
            label: '苹果'
          },
          {
            value: 'android',
            label: '安卓'
          },
        ],
        searchAppChannel: null,
        searchCarType: null,
        activeType: null,
        searchVip: null,
        rewardType: null,
        searchApp: null,
        searchAdId: null,
        apps: [],
        adSettings: [],
        channelSettings: [],
        userId: null
      }
    },
    created() {
      console.log('this.$router.query', this.$route.query)
      this.searchApp = this.$route.query.appId
      const selectDate = this.$route.query.date
      this.searchAppChannel = this.$route.query.appChannel
      this.searchAdId = this.$route.query.adId
      const today = (selectDate ? moment(selectDate) : moment()).format('YYYY-MM-DD')
      this.selectDate = [today, today]
      this.init()
    },
    methods: {
      async init() {
        const res = await userApi.list({
          index: this.currentPage - 1,
          limit: 20,
          key__regex: this.tmpSearchKWs,
          login: this.login ? 1 : null,
          appName: '-1',
          start: this.selectDate ? this.selectDate[0] : null,
          end: this.selectDate ? this.selectDate[1] : null,
          uniPlatform: this.searchUniPlatform ? this.searchUniPlatform : '-1',
          osName: this.searchOsName ? this.searchOsName : '-1',
          appId: this.searchApp,
          activeType: this.activeType,
          appChannel: this.searchAppChannel,
          vip: this.searchVip,
          rewardType: this.rewardType,
          adId: this.searchAdId,
          userId: this.userId
        })
        this.list = res.data.data.list
        this.selectedCount = res.data.data.count

        const res2 = await appApi.list({ownerType: 1})
        this.apps = res2.data.data.list

        const res3 = await attributionApi.adSettings()
        this.adSettings = res3.data.data.list
        const res4 = await attributionApi.channels()
        this.channelSettings = res4.data.data.list
      },
      handlePageChange(page) {
        this.currentPage = page
        this.init()
      },
      handleSearch() {
        this.currentPage = 1
        this.init()
      },
      statusFormat({status}) {
        return this.statusOpts.find(role => role.value === status).label
      },
      dateFormat(time) {
        return moment(time).format('YYYY-MM-DD HH:mm:ss')
      },
      adFormat(row) {
        if (row.attributionAppData) {
          const found = this.adSettings.find(adSetting => adSetting.adId === row.attributionAppData.adId)
          if (found) {
            return found.name
          }
        }
      },
      channelFormat(row) {
        if (row.attributionAppData) {
          const found = this.channelSettings.find(adSetting => adSetting.appChannel === row.attributionAppData.appChannel)
          if (found) {
            return found.name
          }
        }
      },
      uniPlatformFormat({uniPlatform}) {
        const found = this.uniPlatforms.find(role => role.value === uniPlatform)
        if (found) {
          return found.label
        }
        return uniPlatform
      },
    }
  }
</script>

<style scoped lang="less">

</style>
