<template>
  <div class="login-root">
    <div class="bg"></div>
    <div class="form-container">
      <div class="logo">记账归因后台</div>
      <el-form
        ref="ruleFormRef"
        :model="form"
        status-icon
        :rules="rules"
        label-width="60px">
        <el-form-item label="用户名" prop="phone">
          <el-input
            v-model="form.phone"
            size="large"
            autocomplete="off"
            maxlength="11"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            v-model="form.password"
            type="password"
            size="large"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" style="margin-top: 20px" size="large" @click="submitForm(ruleFormRef)">登录</el-button>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'

const router = useRouter()
const store = useStore()
const form = reactive({
  phone: '',
  password: ''
})
const rules = []
const ruleFormRef = ref()

const login = (form) => store.dispatch('login', form)
const submitForm = (formEl) => {
  if (!formEl) return
  formEl.validate(async (valid) => {
    if (valid) {
      try {
        await login(form)
        console.log('store', store)
        await router.replace('/')
      } catch (e) {
        ElMessage({
          type: 'fail',
          message: e.data.msg
        })
      }
      console.log('submit!')
    } else {
      console.log('error submit!')
      return false
    }
  })
}
</script>

<style scoped lang="less">
.login-root {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-position: center;
  //background-image: url("https://wyscout.com/wp-content/uploads/2017/09/4-3-3-ok.jpg");
  background-size: cover;
  padding-top: 200px;

  .bg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    background-position: center;
    //background-image: url("https://platform.wyscout.com/app/platform.engine/ui/css/images/bg.png");
    background-size: cover;
  }

  .form-container {
    margin: 0 auto;
    width: 300px;
    padding: 30px 80px 50px 80px;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    .logo {
      font-size: 40px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 20px;
    }

    .el-form-item {
      flex-direction: column;

      :deep(.el-form-item__label) {
        text-align: left;
      }
    }
  }
}
</style>
