import { commonApi } from '@/api/admin'
import { createStore } from 'vuex'
import { leftMenus as adminLeftMenus } from '@/app/admin/menu'
import { leftMenus as manageLeftMenus } from '@/app/manage/menu'
import { leftMenus as commonLeftMenus } from '@/app/common/menu'

export default createStore({
  state: {
    mine: {},
    role: null,
    selectedTopMenuCode: null,
    permissions: [],
    checkedLogined: false
  },
  getters: {
    menus: state => {
      let menus = []
      if (state.mine) {
        if (state.mine.type === 100) {
          menus = adminLeftMenus
        } else if (state.mine.type === 1 || state.mine.type === 2) {
          menus = manageLeftMenus(state.mine)
        }
      }

      return menus.concat(commonLeftMenus)
    },
    juese: state => {
      if (state.mine) {
        if (state.mine.roleId === 100) {
          return '超级管理员'
        }
      }
      return ''
    },
    isLogined: state => {
      return !!state.mine
    },
    permissions: state => {
      return state.permissions
    },
    mine: state => {
      return state.mine
    },
    leftMenus: state => {
      console.log('state.getters', state)
      const found = state.getters.menus.find(menu => menu.code === state.selectedTopMenuCode)
      if (found) {
        return found.children
      }
      return []
    },
    topMenus: state => {
      const list = []
      state.menus.map(menu => {
        const {
          name,
          code
        } = menu
        list.push({
          name,
          code
        })
      })

      return list
    }
  },
  mutations: {
    login (state, {
      user,
      role,
      permissions
    }) {
      state.mine = user
      state.role = role
      state.permissions = []
    },
    checkedLogined (state) {
      state.checkedLogined = true
    },
    logout (state) {
      state.mine = null
      state.checkedLogined = false
    },
    changeTopMenu (state, key) {
      state.selectedTopMenuCode = key
    },
  },
  actions: {
    changeTopMenu ({ commit }, key) {
      commit('changeTopMenu', key)
    },
    initTopMenu ({
      commit,
      state
    }, url) {
      function scanChildren (obj) {
        if (url === obj.code) {
          return true
        }
        if (obj.children) {
          for (let i = 0; i < obj.children.length; i++) {
            const found = scanChildren(obj.children[i])
            if (found) {
              return true
            }
          }
        }
      }

      const found = state.menus.find(menu => scanChildren(menu))
      console.log(found)
      commit('changeTopMenu', found ? found.code : null)
    },
    async login ({ commit }, {
      phone,
      password
    }) {
      const res = await commonApi.login(phone, password)
      commit('login', res.data.data)
      commit('checkedLogined')
      return true
    },

    async checkLogined ({
      commit,
      state
    }) {
      if (state.checkedLogined) {
        return !!state.mine
      }

      commit('checkedLogined')

      try {
        const res = await commonApi.checkLogined()

        commit('login', res.data.data)


        return true
      } catch (e) {
        console.log(e)
      }

      return false
    },

    async logout ({ commit }) {
      await commonApi.logout()
      commit('logout')
    }
  }
})
