<template>
  <el-dropdown>
    <div style="margin: 5px;display: flex;align-items: center">
      <el-avatar :icon="UserFilled"></el-avatar>
      <span class="username">{{store.getters.juese}} {{ store.getters.mine.name }}</span>
      <el-icon class="el-icon--right">
        <arrow-down/>
      </el-icon>
    </div>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item divided @click="logout">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script setup>
import { UserFilled, ArrowDown } from '@element-plus/icons-vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
const r = useRouter()

const store = useStore()

function logout () {
  r.replace('/login')
}

</script>

<style scoped lang="less">
.el-dropdown {
  cursor: pointer;

  :deep(.el-dropdown--default) {
    align-items: center;
    display: flex;
  }

  .el-icon--right {
    color: #fff;
  }

  .username {
    margin-left: 10px;
    color: #fff;
  }
}
</style>
