<template>
  <el-card shadow="never" header="激活码">
    <div class="flex m-b-15">
      <div class="f1">共计：{{ selectedCount }} 个</div>
    </div>
    <el-table :data="list">
      <el-table-column label="激活码" prop="redeemCode"></el-table-column>
      <el-table-column prop="userInfo.name" label="激活用户">
        <template #default="{row}">
          <router-link v-if="row.userInfo" :to="{name:'manage_user_detail', params:{id:row.userInfo.userId}}"
                       target="_blank">
            <div style="white-space: nowrap">{{ row.userInfo.name ? row.userInfo.name: '未知'}}</div>
          </router-link>
          <div v-else></div>
        </template>
      </el-table-column>
      <el-table-column prop="userInfo.userId" label="激活用户Id"></el-table-column>
      <el-table-column label="激活时间" prop="useAt">
        <template #default="{row}">
          <div>{{ dateFormat(row.useAt) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="status">
        <template #default="{row}">
          <el-tag v-if="!row.status">可使用</el-tag>
          <el-tag type="info" v-else>已使用</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="useAt">
        <template #default="{row}">
          <div>{{ row.created }}</div>
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template #default="{row}">
          <template v-if="!row.status">
            <el-button @click="copy(row.redeemCode)" size="small">复制</el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <PaginationView :current-page="currentPage" @current-change="handlePageChange"
                  :total="selectedCount"></PaginationView>
</template>

<script>
  import {userRedeemCodeApi, redeemChannelApi} from '@/api/fenxiao'
  import {ElMessage, ElMessageBox} from 'element-plus'
  import moment from 'moment/moment'
  import utils from '@/util/utils'

  export default {
    name: 'SettingListScreen',
    data() {
      return {
        showForm: false,
        form: null,
        list: [],
        currentPage: 1,
        selectedCount: 0,
        accounts: [],
        products: [],
        statusArr: [
          {
            val: 0,
            label: '未使用'
          },
          {
            val: 1,
            label: '已使用'
          }
        ]
      }
    },
    watch: {
      '$route.params.id'(id) {
        if (!id) {
          return
        }
        this.init()
      }
    },
    async created() {
      this.init()
    },
    methods: {
      statusFormat(row) {
        return this.statusArr.find(item => item.val === row.status).label
      },
      async init() {
        const res2 = await userRedeemCodeApi.list({
          index: this.currentPage - 1,
          limit: 20,
          from: 2,
          operatorId: this.$route.params.id
        })
        this.list = res2.data.data.list
        this.selectedCount = res2.data.data.count
      },
      async copy(url) {
        await utils.copyToClipboard(url)
        ElMessage({
          type: 'success',
          message: '复制成功'
        })
      },
      dateFormat(time) {
        if (time) {
          return moment(time).format('YYYY-MM-DD HH:mm:ss')
        }
        return ''
      },
      handlePageChange(page) {
        this.currentPage = page
        this.init()
      },
    }
  }
</script>

<style scoped lang="less">
  .cover {
    width: 200px;
    height: 100px;
  }
</style>
