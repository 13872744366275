import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'

import LoginScreen from '@/app/common/screens/Login/LoginScreen.vue'
import BasicLayout from '@/app/common/views/Layout/BasicLayout.vue'
import admin from './admin/router'
import common from './common/router'
import manage from './manage/router'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginScreen
  }, {
    path: '/',
    name: 'need_login',
    component: BasicLayout,
    children: common.concat(admin).concat(manage)
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

async function checkLogin (to) {
  if (to.matched[0] && to.matched[0].name === 'need_login') {
    return await store.dispatch('checkLogined')
  }

  return true
}

function checkPermission (to) {
  // if (to.path.indexOf('/manage') > -1) {
  //   return store.getters.permissions.find(permission => permission.code === to.name)
  // }

  return true
}

router.beforeEach(async (to, from, next) => {
  if (await checkLogin(to)) {
    console.log('login...')
    if (checkPermission(to)) {
      console.log('permission...')

      next()
    } else {
      console.log('not_permission')
      next({
        name: 'not_permission'
      })
    }
  } else {
    next({
      name: 'login',
      query: {
        from: to.fullPath
      }
    })
  }
})
