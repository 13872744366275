<template>
    <el-card shadow="never" :header="short.title" v-if="short">
        <div class="flex m-b-15">
            <div class="f1">共计：{{ list.length }} 个</div>
            <el-button type="primary" @click="handleAdd">添加</el-button>
        </div>
        <el-table :data="list">
            <el-table-column label="id" prop="id" width="100"></el-table-column>
            <el-table-column label="剧目id" prop="shortId"></el-table-column>
            <el-table-column label="剧目标题" prop="short.title"></el-table-column>
            <el-table-column label="权重" prop="weight"></el-table-column>
            <el-table-column width="140">
                <template #default="{row}">
                    <el-button @click="handleEdit(row)" size="small">修改</el-button>
                    <el-button @click="handleRemove(row)" type="danger" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-card>
    <el-dialog title="添加剧目" v-model="showForm">
        <el-form :model="form">
            <el-form-item label="剧目" :label-width="100">
                <el-select v-model="form.shortId" filterable class="m-2" placeholder="请选择剧目">
                    <el-option
                            v-for="item in shortList"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="权重" :label-width="100">
                <el-input v-model="form.weight"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="showForm = false">取消</el-button>
        <el-button type="primary" @click="handleSubmit">提交</el-button>
      </span>
        </template>
    </el-dialog>
</template>

<script>
import {shortChannelApi, shortChannelRecordApi, shortApi} from '@/api/admin'
import { ElMessageBox } from 'element-plus'

export default {
  name: 'ShortChannelRecordListScreen',
  data () {
    return {
      showForm: false,
      form: null,
      short: null,
      list: [],
      shortList: [],
      qiniuData: {
        token: '',
        key: '' // 图片名字处理
      },
    }
  },
  watch: {
    '$route.params' (newVal) {
      console.log('newVal', newVal)
      if (newVal) {
        this.init()
      }
    }
  },
  async created () {
    this.init()
  },
  methods: {
    async init () {
      const res2 = await shortChannelApi.info(this.$route.params.id)
      this.short = res2.data.data

      const res = await shortChannelRecordApi.list({
        no_page: 1,
        channelId: this.$route.params.id
      })
      this.list = res.data.data.list

      const res3 = await shortApi.list({
        no_page: 1,
      })
      this.shortList = res3.data.data.list
    },
    handleAdd () {
      this.showForm = true
      this.form = {
        key: null,
        value: null
      }
    },
    handleEdit (d) {
      this.showForm = true
      this.form = JSON.parse(JSON.stringify(d))
    },
    async handleRemove (d) {
      await ElMessageBox.alert('确认删除', '删除', {
        confirmButtonText: '确认',
        callback: async (action) => {
          if (action === 'confirm') {
            await shortChannelRecordApi.remove(d.id)
            await this.init()
          }
        }
      })
    },
    async handleSubmit () {
      this.form.channelId = this.$route.params.id
      if (this.form.id) {
        await shortChannelRecordApi.update(this.form.id, { data: this.form })
      } else {
        await shortChannelRecordApi.create(this.form)
      }
      this.showForm = false
      await this.init()
    }
  }
}
</script>

<style scoped lang="less">
.cover {
  width: 200px;
  height: 100px;
}
</style>
