import http from './http'

export default class ResourceApi {
  constructor(path) {
    this.path = path
  }

  create(data) {
    return http.post(this.path, {data})
  }

  info(id, params) {
    return http.get(this.path + '/' + id, {params})
  }

  list(params = {}) {
    return http.get(this.path, {params})
  }

  listNextPage(params, pagination) {
    if (!params) {
      params = {}
    }

    params.index = pagination.current + 1
    return this.list(params)
  }

  listNoPage(params = {}) {
    params.no_page = 1
    return this.list(params)
  }

  remove(id) {
    return http.delete(this.path + '/' + id)
  }

  update(id, args = {}) {
    const {
      condition,
      data
    } = args

    return http.put(this.path + '/' + id, {data})
  }
}
