<template>
  <el-card shadow="never" header="Android投放设置">
    <div class="flex m-b-15">
      <div class="f1">共计：{{ list.length }} 个</div>
      <el-button type="primary" @click="handleAdd">添加</el-button>
    </div>
    <el-table :data="list">
<!--      <el-table-column label="id" prop="id" width="100"></el-table-column>-->
      <el-table-column label="备注" prop="desc"></el-table-column>
      <el-table-column label="包名" prop="appId">
        <template #default="{row}">
          <div>{{ appNameFormat(row.appId) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="name" prop="channel.name"></el-table-column>
      <el-table-column label="参与归因" prop="attributionStatus" align="center"></el-table-column>
      <el-table-column label="默认展示" prop="attributionDefaultShow" align="center"></el-table-column>
      <el-table-column label="订单上报期限" prop="orderDayLimit" align="center">
        <template #default="{row}">
          <div>{{ row.orderDayLimit || '不限制' }}</div>
        </template>
      </el-table-column>
      <el-table-column width="200">
        <template #default="{row}">
          <el-button @click="copyUrl(row.callbackUrl)" size="small">复制回调地址</el-button>
          <br/>
          <el-button @click="copyVivoRedirect(row)" size="small" v-if="row.appChannel==='UMENG_CHANNEL_1003m'">复制vivo
            code地址
          </el-button>
          <br/>
          <el-button @click="copyVivoAccessToken(row)" size="small" v-if="row.appChannel==='UMENG_CHANNEL_1003m'">
            复制vivo授权地址
          </el-button>
        </template>
      </el-table-column>
      <el-table-column width="140">
        <template #default="{row}">
          <el-button @click="handleEdit(row)" size="small">修改</el-button>
          <br/>
          <el-button @click="handleRemove(row)" type="danger" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <el-dialog title="添加投放渠道" v-model="showForm">
    <el-form :model="form">
      <el-form-item label="备注" :label-width="180">
        <el-input v-model="form.desc"></el-input>
      </el-form-item>
      <el-form-item label="app" :label-width="180">
        <el-select v-model="form.appId">
          <el-option :value="item.appId" :label="item.appName" v-for="item in apps"
                     :key="item.appId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="渠道" :label-width="180">
        <el-select v-model="form.appChannel">
          <el-option :value="item.appChannel" :label="item.name" v-for="item in appChannels"
                     :key="item.appChannel"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="参与归因" :label-width="180">
        <el-input v-model="form.attributionStatus"></el-input>
      </el-form-item>
      <el-form-item label="默认展示" :label-width="180">
        <el-input v-model="form.attributionDefaultShow"></el-input>
      </el-form-item>
      <el-form-item label="订单上报期限" :label-width="180">
        <el-input v-model="form.orderDayLimit"></el-input>
        <div>0代表不限制，1只上传当天，2包含当天、上一天，以此类推</div>
      </el-form-item>
      <template v-if="selectChannel">
        <el-form-item :label="field" :label-width="180" v-for="field in selectChannel.fields" :key="field">
          <el-input v-model="form[field]" type="textarea" autosize></el-input>
        </el-form-item>
      </template>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showForm = false">取消</el-button>
        <el-button type="primary" @click="handleSubmit">提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { attributionChannelSettingApi, attributionApi, appApi } from '@/api/admin.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import utils from '@/util/utils'

export default {
  name: 'TagListScreen',
  data () {
    return {
      appChannels: [],
      apps: [],
      list: [],
      form: {
        appChannel: null,
        appId: null
      },
      showForm: false
    }
  },
  computed: {
    selectChannel () {
      if (this.form) {
        return this.appChannels.find(channel => channel.appChannel === this.form.appChannel)
      }
      return null
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      const res3 = await appApi.list({
        no_page: 1,
        attribution: 1
      })
      this.apps = res3.data.data.list
      const res2 = await attributionApi.channels({
        no_page: 1
      })
      const list = res2.data.data.list
      list.map(item => {
        if (item.settingFields) {
          item.fields = item.settingFields.split(',')
        } else {
          item.fields = []
        }
      })
      this.appChannels = list

      const res = await attributionChannelSettingApi.list({
        no_page: 1
      })
      const list2 = res.data.data.list
      list2.map(item => {
        if (item.channel.callbackUrl) {
          item.callbackUrl = item.channel.callbackUrl.replace('#appId#', item.appId).replace('#id#', item._id)
        }
      })
      this.list = list2
    },
    handleAdd () {
      this.showForm = true
      this.form = {
        name: null
      }
    },
    appNameFormat (appId) {
      const found = this.apps.find(app => app.appId === appId)
      if (found) {
        return found.appName
      }
      return appId
    },
    handleEdit (d) {
      this.showForm = true
      this.form = JSON.parse(JSON.stringify(d))
    },
    async copyUrl (url) {
      await utils.copyToClipboard(url)
      ElMessage({
        type: 'success',
        message: '复制成功'
      })
    },
    async copyVivoRedirect (data) {
      if (!data.vivoClientId || !data.vivoState || !data.vivoRedirectUrl) {
        return ElMessage({
          type: 'success',
          message: '请填写：vivoClientId、vivoState、vivoRedirectUrl'
        })
      }

      const url = `https://open-ad.vivo.com.cn/OAuth?clientId=${data.vivoClientId}&state=${data.vivoState}&redirectUri=${data.vivoRedirectUrl}`
      await utils.copyToClipboard(url)
      ElMessage({
        type: 'success',
        message: '复制成功'
      })
    },
    async copyVivoAccessToken (data) {
      if (!data.vivoClientId || !data.vivoClientSecret || !data.vivoCode) {
        return ElMessage({
          type: 'success',
          message: '请填写：vivoClientId、vivoClientSecret、vivoCode'
        })
      }
      const url = `https://marketing-api.vivo.com.cn/openapi/v1/oauth2/token?client_id=${data.vivoClientId}&client_secret=${data.vivoClientSecret}&grant_type=code&code=${data.vivoCode}`
      await utils.copyToClipboard(url)
      ElMessage({
        type: 'success',
        message: '复制成功'
      })
    },
    async handleRemove (d) {
      await ElMessageBox.alert('确认删除', '删除', {
        confirmButtonText: '确认',
        callback: async (action) => {
          if (action === 'confirm') {
            await attributionChannelSettingApi.remove(d._id)
            await this.init()
          }
        }
      })
    },
    async handleSubmit () {
      if (this.form._id) {
        await attributionChannelSettingApi.update(this.form._id, { data: this.form })
      } else {
        await attributionChannelSettingApi.create(this.form)
      }
      this.showForm = false
      await this.init()
    }
  }
}
</script>

<style scoped lang="less">

</style>
