import {createApp} from 'vue';
import App from './App.vue';
import router from './app/router.js';
import store from './store';

import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import 'element-plus/dist/index.css';
import 'normalize.css';
import 'vue-json-pretty/lib/styles.css';

import config from '@/config';
import PaginationView from '@/components/PaginationView.vue';

const env = process.env.NODE_ENV;
// const env = 'production'

config.api = env === 'production' ? location.protocol + '//' + 'api.xiaokoudai.cn/api/xdjz/' : 'http://localhost:7010/api/xdjz/';

// config.api = env === 'production' ? location.protocol + '//' + 'api.dev.xiaokoudai.cn/api/xdjz/' : 'http://api.dev.xiaokoudai.cn/api/';

createApp(App).component('PaginationView', PaginationView).use(ElementPlus, {
  locale: zhCn
}).use(store).use(router).mount('#app');
