<template>
  <el-card shadow="never" header="政策">
    <div class="flex m-b-15">
      <div class="f1">共计：{{ list.length }} 个</div>
      <el-button type="primary" @click="handleAdd">添加</el-button>
    </div>
    <el-table :data="list">
      <el-table-column label="id" prop="id" width="100"></el-table-column>
      <el-table-column label="标题" prop="label">
      </el-table-column>
      <el-table-column label="描述" prop="tips">
      </el-table-column>
      <el-table-column label="icon">
        <template #default="{row}">
          <img :src="row.icon" style="max-height: 100px;max-width: 200px">
        </template>
      </el-table-column>
      <el-table-column label="图标">
        <template #default="{row}">
          <img :src="row.pic" style="max-height: 100px;max-width: 200px">
        </template>
      </el-table-column>
      <el-table-column width="140">
        <template #default="{row}">
          <el-button @click="handleEdit(row)" size="small">修改</el-button>
          <el-button v-if="row.id != 14 && row.id != 16" @click="handleRemove(row)" type="danger" size="small">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <el-dialog title="添加政策" v-model="showForm">
    <el-form :model="form">
      <el-form-item label="标题" :label-width="60">
        <el-input v-model="form.label"></el-input>
      </el-form-item>
      <el-form-item label="描述" :label-width="60">
        <el-input v-model="form.tips"></el-input>
      </el-form-item>
      <el-form-item label="icon" :label-width="60">
        <el-upload
          class="avatar-uploader"
          action="http://up-na0.qiniup.com"
          :show-file-list="false"
          :data="qiniuData"
          multiple
          :on-success="handleAvatarSuccess('icon')"
          :before-upload="beforeAvatarUpload">
          <template #default>
            <img v-if="form.icon" :src="form.icon" style="height: 100px"/>
            <el-button v-else>选择</el-button>
          </template>
        </el-upload>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showForm = false">取消</el-button>
        <el-button type="primary" @click="handleSubmit">提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { commonApi, privilegeApi } from '@/api/admin'

export default {
  name: 'AppIconListScreen',
  data () {
    return {
      showForm: false,
      form: null,
      list: [],
      qiniuData: {
        token: '',
        key: '' // 图片名字处理
      }
    }
  },
  async created () {
    this.init()
  },
  methods: {
    async init () {
      const res = await privilegeApi.list({
        no_page: 1
      })
      this.list = res.data.data.list
    },
    handleAdd () {
      this.showForm = true
      this.form = {
        label: null
      }
    },
    handleEdit (d) {
      this.showForm = true
      this.form = JSON.parse(JSON.stringify(d))
    },
    async handleRemove (d) {
      await privilegeApi.remove(d.id)
      await this.init()
    },
    async handleSubmit () {
      if (this.form.id) {
        await privilegeApi.update(this.form.id, { data: this.form })
      } else {
        await privilegeApi.create(this.form)
      }
      this.showForm = false
      await this.init()
    },
    async beforeAvatarUpload (file) {
      const res3 = await commonApi.getUploadToken()
      this.qiniuData.token = res3.data.data.token
      this.qiniuData.key = 'test/crush/' + Date.now()
    },
    handleAvatarSuccess (field) {
      return (res, file) => {
        const url = 'http://down.qwdata.vip/' + res.key
        this.form[field] = url
      }
    }
  }
}
</script>

<style scoped lang="less">

</style>
