<template>
  <div></div>
</template>

<script>
export default {
  name: 'FooterView',
  data () {
    return {}
  }
}
</script>

<style scoped lang="less">

</style>
