<template>
    <el-card shadow="never" header="剧集管理">
        <div class="flex m-b-15">
            <div class="f1">共计：{{ list.length }} 个</div>
            <el-button type="primary" @click="handleAdd">添加</el-button>
        </div>
        <el-table :data="list">
            <el-table-column label="id" prop="id" width="100"></el-table-column>
            <el-table-column label="标题" prop="title"></el-table-column>
            <el-table-column label="封面" prop="key">
                <template #default="{row}">
                    <img :src="row.coverUrl" v-if="row.coverUrl" class="cover">
                    <div v-else>无</div>
                </template>
            </el-table-column>
            <el-table-column label="描述" prop="desc"></el-table-column>
            <el-table-column label="集数" prop="total"></el-table-column>
            <el-table-column label="所属文件夹" prop="storageDir"></el-table-column>
            <el-table-column width="200">
                <template #default="{row}">
                    <el-button @click="toDetail(row)" size="small" type="primary">详情</el-button>
                    <el-button @click="handleEdit(row)" size="small">修改</el-button>
                    <el-button @click="handleRemove(row)" type="danger" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-card>
    <el-dialog title="添加剧目" v-model="showForm">
        <el-form :model="form">
            <el-form-item label="标题" :label-width="100">
                <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="封面" :label-width="100">
                <el-upload
                        class="avatar-uploader"
                        action="http://up-na0.qiniup.com"
                        :show-file-list="false"
                        :data="qiniuData"
                        :on-success="handleSuccess('coverUrl')"
                        :before-upload="beforeUpload('short/cover/')">
                    <template #default>
                        <img v-if="form.coverUrl" :src="form.coverUrl" style="height: 100px"/>
                        <el-button v-else>选择</el-button>
                    </template>
                </el-upload>
            </el-form-item>
            <el-form-item label="描述" :label-width="100">
                <el-input v-model="form.desc" type="textarea"></el-input>
            </el-form-item>
            <el-form-item label="集数" :label-width="100">
                <el-input v-model="form.total"></el-input>
            </el-form-item>
            <el-form-item label="所属文件夹" :label-width="100">
                <el-input v-model="form.storageDir"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="showForm = false">取消</el-button>
        <el-button type="primary" @click="handleSubmit">提交</el-button>
      </span>
        </template>
    </el-dialog>
</template>

<script>
  import {commonApi, shortApi} from '@/api/admin'
  import {ElMessageBox} from 'element-plus'

  export default {
    name: 'ShortListScreen',
    data() {
      return {
        showForm: false,
        form: null,
        list: [],
        qiniuData: {
          token: '',
          key: '' // 图片名字处理
        },
        statusArr: [
          {
            val: 1,
            label: '连载'
          },
          {
            val: 2,
            label: '完结'
          },
          {
            val: -1,
            label: '隐藏'
          }
        ]
      }
    },
    watch: {
      '$route.params'(newVal) {
        console.log('newVal', newVal)
        if (newVal) {
          this.init()
        }
      }
    },
    async created() {
      this.init()
    },
    methods: {
      async init() {
        const res = await shortApi.list({
          page: this.currentPage
        })
        this.list = res.data.data.list
      },
      toDetail(d) {
        this.$router.push({
          name: 'short_episode_list',
          params: {
            id: d.id
          }
        })
      },
      handleAdd() {
        this.showForm = 'add'
        this.form = {
          key: null,
          value: null
        }
      },
      handleEdit(d) {
        this.showForm = 'edit'
        this.form = JSON.parse(JSON.stringify(d))
      },
      async handleRemove(d) {
        await ElMessageBox.alert('确认删除', '删除', {
          confirmButtonText: '确认',
          callback: async (action) => {
            if (action === 'confirm') {
              await shortApi.remove(d.id)
              await this.init()
            }
          }
        })
      },
      async handleSubmit() {
        if (this.showForm === 'edit') {
          await shortApi.update(this.form.id, {data: this.form})
        } else {
          await shortApi.create(this.form)
        }
        this.showForm = null
        await this.init()
      },
      statusFormat(row) {
        return this.statusArr.find(item => item.val === row.status).label
      },
      handleSuccess(props) {
        return async (res, file) => {
          const url = 'http://resources.dramashort01.com/' + res.key
          this.form[props] = url
        }
      },
      beforeUpload(prefix) {
        return async () => {
          const res3 = await commonApi.getUploadToken()
          this.qiniuData.token = res3.data.data.token
          this.qiniuData.key = prefix + Date.now()
        }
      }
    }
  }
</script>

<style scoped lang="less">
    .cover {
        width: 135px;
        height: 200px;
    }
</style>
