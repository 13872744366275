<template>
  <el-card shadow="never" header="Android渠道">
    <div class="flex m-b-15">
      <div class="f1">共计：{{ list.length }} 个</div>
      <el-button type="primary" @click="handleAdd">添加</el-button>
    </div>
    <el-table :data="list">
<!--      <el-table-column label="id" prop="id" width="100"></el-table-column>-->
      <el-table-column label="name" prop="name" width="120"></el-table-column>
      <el-table-column label="appChannel" prop="appChannel" width="210"></el-table-column>
      <el-table-column label="设置参数" prop="settingFields" width="210">
        <template #default="{row}">
          <div v-for="item in row.settingFieldArr" :key="item">{{item}}</div>
        </template>
      </el-table-column>
      <el-table-column label="默认回调地址" prop="callbackUrl"></el-table-column>
      <el-table-column width="140">
        <template #default="{row}">
          <el-button @click="handleEdit(row)" size="small">修改</el-button>
          <el-button @click="handleRemove(row)" type="danger" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <el-dialog title="添加渠道" v-model="showForm">
    <el-form :model="form">
      <el-form-item label="name" :label-width="120">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="appChannel" :label-width="120">
        <el-input v-model="form.appChannel"></el-input>
      </el-form-item>
      <el-form-item label="设置参数" :label-width="120">
        <el-input v-model="form.settingFields" type="textarea" autosize></el-input>
      </el-form-item>
      <el-form-item label="回调地址" :label-width="120">
        <el-input v-model="form.callbackUrl" type="textarea" autosize></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showForm = false">取消</el-button>
        <el-button type="primary" @click="handleSubmit">提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { attributionChannelApi } from '@/api/admin.js'
import { ElMessageBox } from 'element-plus'

export default {
  name: 'TagListScreen',
  data () {
    return {
      list: [],
      form: {
        name: null
      },
      showForm: false
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      const res = await attributionChannelApi.list({
        no_page: 1
      })
      const list = res.data.data.list
      list.map(item => {
        item.settingFieldArr = []
        if (item.settingFields) {
          item.settingFieldArr = item.settingFields.split(',')
        }
      })

      this.list = list
    },
    handleAdd () {
      this.showForm = true
      this.form = {
        name: null
      }
    },
    handleEdit (d) {
      this.showForm = true
      this.form = JSON.parse(JSON.stringify(d))
    },
    async handleRemove (d) {
      await ElMessageBox.alert('确认删除', '删除', {
        confirmButtonText: '确认',
        callback: async (action) => {
          if (action === 'confirm') {
            await attributionChannelApi.remove(d._id)
            await this.init()
          }
        }
      })
    },
    async handleSubmit () {
      if (this.form._id) {
        console.log(this.form._id);
        console.log(this.form);
        await attributionChannelApi.update(this.form._id, { data: this.form })
      } else {
        await attributionChannelApi.create(this.form)
      }
      this.showForm = false
      await this.init()
    }
  }
}
</script>

<style scoped lang="less">

</style>
