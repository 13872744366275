<template>
  <el-card shadow="never" header="提示词管理">
    <el-table :data="list">
      <el-table-column label="模块" prop="title" width="100"></el-table-column>
      <el-table-column label="提示词" prop="content" width="800"></el-table-column>
      <el-table-column label="创建时间" prop="created" width="100">
        <template #default="{row}">
          <div>{{ row.created }}</div>
        </template>
      </el-table-column>
      <el-table-column width="200">
        <template #default="{row}">
          <el-button @click="handleEdit(row)" size="small">修改</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <el-dialog title="提示词" v-model="showForm">
    <el-form :model="form">
      <el-form-item label="模块" :label-width="100">
        <el-input v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="config1st" :label-width="100">
        <el-input v-model="form.config1st" type="textarea" :rows="2"></el-input>
      </el-form-item>
      <el-form-item label="config2nd" :label-width="100">
        <div>{{form.configDes2nd}}</div>
      </el-form-item>
      <el-form-item label="config3rd" :label-width="100">
        <el-input v-model="form.config3rd" type="textarea" :rows="5"></el-input>
      </el-form-item>
      <el-form-item label="config4st" :label-width="100">
        <div>{{form.todayStr}}</div>
      </el-form-item>
      <el-form-item label="config5th" :label-width="100">
        <el-input v-model="form.config5th" type="textarea" :rows="4"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showForm = false">取消</el-button>
        <el-button type="primary" @click="handleSubmit">提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
  import {aiActionConfigApi} from '@/api/ai'
  import {ElMessageBox} from 'element-plus'

  export default {
    name: 'SettingListScreen',
    data() {
      return {
        showForm: false,
        form: null,
        list: [],
        statusArr: [
          {
            val: 0,
            label: '不可用'
          },
          {
            val: 1,
            label: '可用'
          }
        ]
      }
    },
    watch: {
      '$route.params'(newVal) {
        console.log('newVal', newVal)
        if (newVal) {
          this.init()
        }
      }
    },
    async created() {
      this.init()
    },
    methods: {
      async init() {
        const res = await aiActionConfigApi.list({
          no_page: 1
        })
        this.list = res.data.data.list
      },
      handleAdd() {
        this.showForm = 'add'
        this.form = {
          key: null,
          value: null
        }
      },
      handleEdit(d) {
        this.showForm = 'edit'
        this.form = JSON.parse(JSON.stringify(d))
      },
      async handleRemove(d) {
        await ElMessageBox.alert('确认删除', '删除', {
          confirmButtonText: '确认',
          callback: async (action) => {
            if (action === 'confirm') {
              await aiActionConfigApi.remove(d._id)
              await this.init()
            }
          }
        })
      },
      async handleSubmit() {
        if (this.showForm === 'edit') {
          await aiActionConfigApi.update(this.form._id, {data: this.form})
        } else {
          await aiActionConfigApi.create(this.form)
        }
        this.showForm = null
        await this.init()
      }
    }
  }
</script>

<style scoped lang="less">
  .cover {
    width: 200px;
    height: 100px;
  }
</style>
