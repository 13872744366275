<template>
    <el-card shadow="never" :header="short.title" v-if="short">
        <div class="flex m-b-15">
            <div class="f1">共计：{{ list.length }} 个</div>
            <el-button type="primary" @click="handleAdd">添加</el-button>
        </div>
        <el-table :data="list">
            <el-table-column label="id" prop="id" width="100"></el-table-column>
            <el-table-column label="第几集" prop="index"></el-table-column>
            <el-table-column label="链接" prop="url"></el-table-column>
            <el-table-column label="扣分" prop="cost"></el-table-column>
            <el-table-column label="状态" prop="status">
                <template #default="{row}">
                    {{statusFormat(row)}}
                </template>
            </el-table-column>
            <el-table-column label="付费" prop="payType">
                <template #default="{row}">
                    {{statusPayType(row)}}
                </template>
            </el-table-column>
            <el-table-column width="140">
                <template #default="{row}">
                    <el-button @click="handleEdit(row)" size="small">修改</el-button>
                    <el-button @click="handleRemove(row)" type="danger" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-card>
    <el-dialog title="添加剧集" v-model="showForm">
        <el-form :model="form">
            <el-form-item label="第几集" :label-width="100">
                <el-input v-model="form.index"></el-input>
            </el-form-item>
            <el-form-item label="链接" :label-width="100">
                <el-input v-model="form.url"></el-input>
            </el-form-item>
            <el-form-item label="状态" :label-width="100">
                <el-input v-model="form.status"></el-input>
            </el-form-item>
            <el-form-item label="付费" :label-width="100">
                <el-input v-model="form.payType"></el-input>
            </el-form-item>
            <el-form-item label="扣分" :label-width="100">
                <el-input v-model="form.cost"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="showForm = false">取消</el-button>
        <el-button type="primary" @click="handleSubmit">提交</el-button>
      </span>
        </template>
    </el-dialog>
</template>

<script>
  import {shortApi, shortEpisodeApi} from '@/api/admin'
  import {ElMessageBox} from 'element-plus'

  export default {
    name: 'ShortChannelRecordListScreen',
    data() {
      return {
        showForm: false,
        form: null,
        short: null,
        list: [],
        qiniuData: {
          token: '',
          key: '' // 图片名字处理
        },
        statusArr: [
          {
            val: 0,
            label: '隐藏'
          },
          {
            val: 1,
            label: '可用'
          }
        ],
        payTypeArr: [
          {
            val: 0,
            label: '免费'
          },
          {
            val: 1,
            label: '付费'
          }
        ],
      }
    },
    watch: {
      '$route.params'(newVal) {
        console.log('newVal', newVal)
        if (newVal && newVal.id) {
          this.init()
        }
      }
    },
    async created() {
      this.init()
    },
    methods: {
      async init() {
        const res2 = await shortApi.info(this.$route.params.id)
        this.short = res2.data.data

        const res = await shortEpisodeApi.list({
          no_page: 1,
          shortId: this.$route.params.id
        })
        this.list = res.data.data.list
      },
      handleAdd() {
        this.showForm = true
        this.form = {
          key: null,
          value: null
        }
      },
      handleEdit(d) {
        this.showForm = true
        this.form = JSON.parse(JSON.stringify(d))
      },
      async handleRemove(d) {
        await ElMessageBox.alert('确认删除', '删除', {
          confirmButtonText: '确认',
          callback: async (action) => {
            if (action === 'confirm') {
              await shortEpisodeApi.remove(d.id)
              await this.init()
            }
          }
        })
      },
      async handleSubmit() {
        this.form.shortId = this.$route.params.id
        if (this.form.id) {
          await shortEpisodeApi.update(this.form.id, {data: this.form})
        } else {
          await shortEpisodeApi.create(this.form)
        }
        this.showForm = false
        await this.init()
      },
      statusFormat(row) {
        return this.statusArr.find(item => item.val === row.status).label
      },
      statusPayType(row) {
        return this.payTypeArr.find(item => item.val === row.payType).label
      }
    }
  }
</script>

<style scoped lang="less">
    .cover {
        width: 200px;
        height: 100px;
    }
</style>
