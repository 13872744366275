<template>
  <el-card shadow="never" header="商品" class="m-b-15">
    <div class="flex h vc">
      <el-select placeholder="平台" v-model="selectPlatform" style="width: 150px" class="m-r-15" clearable>
        <el-option :value="item.value" :label="item.label" v-for="item in platforms" :key="item.value"></el-option>
      </el-select>
      <el-select placeholder="类型" v-model="selectType" style="width: 150px" class="m-r-15" clearable>
        <el-option :value="item.value" :label="item.label" v-for="item in productTypes" :key="item.value"></el-option>
      </el-select>
      <el-button type="primary" @click="handleSearch">查询</el-button>
      <div class="f1"></div>
      <el-button type="primary" @click="handleAdd">添加</el-button>
    </div>
  </el-card>
  <el-table :data="list" stripe border>
    <el-table-column label="id" prop="id" width="60"></el-table-column>
    <el-table-column label="内购ID" prop="iosInAppId"  width="150"></el-table-column>
    <el-table-column label="offerId" prop="offerId" width="100"></el-table-column>
    <el-table-column label="内容">
      <template #default="{row}">
        <div v-if="row.type===1">会员</div>
        <div v-if="row.type===2">
          充{{row.coins}}，送{{row.bones}}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="类型">
      <template #default="{row}">
        <div v-if="row.type === 1">订阅：{{ vipDayFormat(row) }}</div>
        <div v-else>内购</div>
      </template>
    </el-table-column>
    <el-table-column label="平台" prop="platform">
      <template #default="{row}">
        {{ platformFormat(row) }}
      </template>
    </el-table-column>
    <el-table-column label="价格" prop="price" align="right">
      <template #default="{row}">
        {{ priceFormat(row.price) }}
      </template>
    </el-table-column>
    <el-table-column label="原价" prop="oldPrice" align="right">
      <template #default="{row}">
        {{ priceFormat(row.oldPrice) }}
      </template>
    </el-table-column>
    <el-table-column>
      <template #default="{row}">
        <el-button @click="handleEdit(row)" size="small">修改</el-button>
        <el-button @click="handleRemove(row)" type="danger" size="small" v-if="row.id!==1 && row.id!==2 && row.id!==3">
          删除
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-dialog title="添加商品" v-model="showForm">
    <el-form :model="form">
      <el-form-item label="价格（分）" :label-width="120">
        <el-input v-model="form.price"></el-input>
      </el-form-item>
      <el-form-item label="历史价（分）" :label-width="120">
        <el-input v-model="form.oldPrice"></el-input>
      </el-form-item>
      <el-form-item label="平台" :label-width="120">
        <el-select v-model="form.platform">
          <el-option :value="item.value" :label="item.label" v-for="item in platforms" :key="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="内购ID" :label-width="120">
        <el-input v-model="form.iosInAppId"></el-input>
      </el-form-item>
      <el-form-item label="offerId" :label-width="120">
        <el-input v-model="form.offerId"></el-input>
      </el-form-item>
      <el-form-item label="类型" :label-width="120">
        <el-select v-model="form.type" style="margin-right: 15px">
          <el-option :label=type.label :value="type.value" v-for="type in productTypes" :key="type.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="vip日期" :label-width="120" v-if="form.type === 1">
        <el-input v-model="form.vipCount"></el-input>
      </el-form-item>
      <el-form-item label="vip日期单位" :label-width="120" v-if="form.type === 1">
        <el-select v-model="form.vipUnit" style="margin-right: 15px">
          <el-option label="日" :value="1"></el-option>
          <el-option label="周" :value="2"></el-option>
          <el-option label="月" :value="3"></el-option>
          <el-option label="季" :value="4"></el-option>
          <el-option label="年" :value="5"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="买金币" :label-width="120">
        <el-input v-model="form.coins"></el-input>
      </el-form-item>
      <el-form-item label="送金币" :label-width="120">
        <el-input v-model="form.bones"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showForm = false">取消</el-button>
        <el-button type="primary" @click="handleSubmit">提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { productApi } from '@/api/admin'
import { ElMessage, ElMessageBox } from 'element-plus'
import { platformFormat, platforms, priceFormat, productTypeFormat, productTypes } from '@/util/type'

export default {
  name: 'ProductListScreen',
  data () {
    return {
      selectPlatform: 1,
      selectType: null,
      showForm: false,
      form: null,
      priceForm: null,
      list: [],
      platforms,
      productTypes
    }
  },
  async created () {
    this.init()
  },
  methods: {
    priceFormat,
    async init () {
      const res = await productApi.list({
        no_page: 1,
        platform: this.selectPlatform,
        type: this.selectType
      })
      const list = res.data.data.list
      list.map(item => {
        if (!item.prices) {
          item.prices = []
        }
        item.prices.push({
          platform: 'default',
          price: item.price
        })
      })

      this.list = list
      console.log(this.list)
    },
    handleAdd () {
      this.showForm = true
      this.form = {
        price: null,
        oldPrice: null,
        vipCount: null,
        vipUnit: null,
        title: null,
        type: null
      }
    },
    handleSearch () {
      this.init()
    },
    handleEdit (d) {
      this.showForm = true
      this.form = JSON.parse(JSON.stringify(d))
    },
    async handleRemove (d) {
      await ElMessageBox.alert('确认删除', '删除', {
        confirmButtonText: '确认',
        callback: async (action) => {
          if (action === 'confirm') {
            await productApi.remove(d.id)
            await this.init()
            ElMessage({
              type: 'success',
              message: '删除成功'
            })
          }
        }
      })
    },
    async handleSubmit () {
      if (this.form.id) {
        await productApi.update(this.form.id, { data: this.form })
      } else {
        await productApi.create(this.form)
      }
      this.showForm = false
      await this.init()
    },
    vipDayFormat (product) {
      if (product.vipCount) {
        const unitList = ['', '日', '周', '月', '季', '年']
        const unit = unitList[product.vipUnit]
        return product.vipCount + unit
      }
      return '-'
    },
    productTypeFormat,
    platformFormat
  }
}
</script>

<style scoped lang="less">

</style>
