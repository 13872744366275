<template>
  <el-container id="basic-layout-root">
    <div class="line"></div>
    <el-container id="basic-layout-body-root" style="flex: 20">
      <el-header>
        <HeaderView></HeaderView>
      </el-header>
      <el-container>
        <MenuView></MenuView>
        <div style="overflow: auto" class="basic-layout-body-container">
          <div id="basic-layout-main-root" class="">
            <router-view></router-view>
            <el-backtop :right="100" :bottom="100"/>
          </div>
          <el-footer>
            <FooterView></FooterView>
          </el-footer>
        </div>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import HeaderView from './HeaderView.vue'
import FooterView from './FooterView.vue'
import MenuView from './MenuView.vue'

export default {
  name: 'BasicLayout',
  components: {
    MenuView,
    HeaderView,
    FooterView
  }
}
</script>

<style scoped lang="less">
@lineHeight: 0px;
#basic-layout-root {
  width: 100%;
  height: calc(100% - @lineHeight);
  padding-top: @lineHeight;
  display: flex;
  --el-main-padding: 20px;

  .line {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: @lineHeight;
    background-color: #1990ff;
  }

  .basic-layout-body-container {
    overflow: auto;
    position: absolute;
    left: 15vw;
    top: 60px;
    right: 0;
  }

  #basic-layout-body-root {
    height: 100%;
    flex: 1;

    > .el-header {
      //background-color: #fff;
      background-color: #001529;
      width: 100%;
      z-index: 20;
      color: #fff;
      box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
      position: fixed;
    }

    #basic-layout-main-root {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;

      &.wide {
        max-width: 1200px;
        margin: 0 auto;
        width: 100%;
        margin-top: 60px;
      }
    }
  }

}
</style>
