<template>
  <el-card shadow="never" header="广告">
    <div class="m-b-15 flex h vc">
      <el-select placeholder="APP" v-model="selectAppId" style="width: 150px" clearable class="m-r-15">
        <el-option :value="item.appId" :label="item.appName" v-for="item in apps" :key="item.id"></el-option>
      </el-select>
      <el-select placeholder="appChannel" v-model="selectAppChannel" style="width: 150px" clearable class="m-r-15">
        <el-option :value="item.appChannel" :label="item.channel.name" v-for="item in appChannels"
                   :key="item.appChannel"></el-option>
      </el-select>
      <div class="m-r-15">共计：{{ ads.length }} 个</div>
      <el-button type="primary" @click="toSearch">查询</el-button>
      <div class="f1"></div>
      <el-button type="primary" @click="handleAdd">添加</el-button>
    </div>
  </el-card>
  <el-table :data="ads">
    <el-table-column type="index" width="100"></el-table-column>
<!--    <el-table-column label="id" prop="id" width="100"></el-table-column>-->
    <el-table-column label="name" prop="name"></el-table-column>
    <el-table-column label="type" prop="adId"></el-table-column>
    <el-table-column label="appId" prop="appId">
      <template #default="{row}">
        {{ appFormat(row.appId) }}
      </template>
    </el-table-column>
    <el-table-column label="appChannel" prop="appChannel">
      <template #default="{row}">
        {{ appChannelFormat(row.appChannel) }}
      </template>
    </el-table-column>
    <el-table-column prop="createdAt" label="创建时间" width="110" align="center">
      <template #default="{row}">
        <div>{{ row.created }}</div>
      </template>
    </el-table-column>
    <el-table-column width="140">
      <template #default="{row}">
        <el-button @click="handleEdit(row)" size="small">修改</el-button>
        <el-button @click="handleRemove(row)" type="danger" size="small">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-dialog title="添加广告" v-model="showForm">
    <el-form :model="form">
      <el-form-item label="app" :label-width="120">
        <el-select v-model="form.appId">
          <el-option :value="item.appId" :label="item.appName" v-for="item in apps" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="appChannel" :label-width="120">
        <el-select v-model="form.appChannel">
          <el-option :value="item.appChannel" :label="item.channel.name" v-for="item in formSelectAppChannels"
                     :key="item.appChannel"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="name" :label-width="120">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="adId" :label-width="120">
        <el-input v-model="form.adId"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showForm = false">取消</el-button>
        <el-button type="primary" @click="handleSubmit">提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { appApi, attributionADSettingApi, attributionChannelApi, attributionChannelSettingApi } from '@/api/admin.js'
import { ElMessageBox } from 'element-plus'
import moment from 'moment'

export default {
  name: 'TagListScreen',
  data () {
    return {
      list: [],
      selectAppId: null,
      selectAppChannel: null,
      apps: [],
      channelSettings: [],
      channels: [],
      form: {
        appId: null,
        appChannel: null
      },
      showForm: false
    }
  },
  computed: {
    appChannels () {
      return this.channelSettings.filter(channel => channel.appId === this.selectAppId)
    },
    ads () {
      if (this.selectAppChannel) {
        return this.list.filter(ad => ad.appChannel === this.selectAppChannel)
      }
      if (this.selectAppId) {
        return this.list.filter(ad => ad.appId === this.selectAppId)
      }

      return this.list
    },
    formSelectAppChannels () {
      if (this.form) {
        return this.channelSettings.filter(channel => channel.appId === this.form.appId)
      }
      return []
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      const res2 = await attributionChannelSettingApi.list({
        no_page: 1
      })
      this.channelSettings = res2.data.data.list

      const res = await attributionChannelApi.list({
        no_page: 1
      })
      this.channels = res.data.data.list

      const res3 = await appApi.list({
        no_page: 1,
        attribution: 1
      })
      this.apps = res3.data.data.list

      this.query()
    },
    async query () {
      const res = await attributionADSettingApi.list({
        no_page: 1,
        appId: this.selectAppId,
        appChannel: this.selectAppChannel
      })
      this.list = res.data.data.list
    },
    handleAdd () {
      this.showForm = true
      this.form = {
        name: null
      }
    },
    dateFormat (time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    },
    toSearch () {
      this.query()
    },
    handleEdit (d) {
      this.showForm = true
      this.form = JSON.parse(JSON.stringify(d))
    },
    appFormat (appId) {
      const found = this.apps.find(app => app.appId === appId)
      if (found) {
        return found.appName
      }

      return appId
    },
    appChannelFormat (appChannel) {
      const found = this.channels.find(app => app.appChannel === appChannel)
      if (found) {
        return found.name
      }

      return appChannel
    },
    async handleRemove (d) {
      await ElMessageBox.alert('确认删除', '删除', {
        confirmButtonText: '确认',
        callback: async (action) => {
          if (action === 'confirm') {
            await attributionADSettingApi.remove(d._id)
            await this.init()
          }
        }
      })
    },
    async handleSubmit () {
      if (this.form._id) {
        await attributionADSettingApi.update(this.form._id, { data: this.form })
      } else {
        await attributionADSettingApi.create(this.form)
      }
      this.showForm = false
      await this.init()
    }
  }
}
</script>

<style scoped lang="less">

</style>
