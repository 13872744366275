export default {
  async copyToClipboard (textToCopy) {
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(textToCopy)
    } else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement('textarea')
      textArea.value = textToCopy

      // Move textarea out of the viewport so it's not visible
      textArea.style.position = 'absolute'
      textArea.style.left = '-999999px'

      document.body.prepend(textArea)
      textArea.select()

      try {
        document.execCommand('copy')
      } catch (error) {
        console.error(error)
      } finally {
        textArea.remove()
      }
    }
  }
}

export const ownerTypes = [
  {
    label: '自营',
    value: 1
  },
  {
    label: '联营',
    value: 2
  }
]
export const appTypeOpts = [
  {
    label: '泛知识UI类型',
    value: 1
  },
  {
    label: '普通UI类型',
    value: 2
  },
  {
    label: '泛知识+VIP UI类型',
    value: 3
  }
]
export const appTypeFormat = (appType) => {
  const found = appTypeOpts.find(item => item.value === appType)
  if (found) {
    return found.label
  }
  return '未知：' + appType
}
export const ownerTypeFormat = (appType) => {
  const found = ownerTypes.find(item => item.value === appType)
  if (found) {
    return found.label
  }
  return '未知：' + appType
}

export const statusOpts = [
  {
    label: '可用',
    value: 1
  },
  {
    label: '暂停访问',
    value: 0
  }
]

export const statusFormat = (status) => {
  const found = statusOpts.find(item => item.value === status)
  if (found) {
    return found.label
  }
  return '未知：' + status
}
