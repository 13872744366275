<template>
    <el-card shadow="never" header="账号管理">
        <el-descriptions border column="1">
            <el-descriptions-item label="昵称">{{ mine.name }}</el-descriptions-item>
            <el-descriptions-item label="状态">{{ mine.status ? '正常' : '不可用' }}</el-descriptions-item>
            <el-descriptions-item label="手机号">{{ mine.phone }}</el-descriptions-item>
            <el-descriptions-item label="邮箱">{{ mine.email || '无' }}</el-descriptions-item>
            <el-descriptions-item label="注册时间">{{ dateFormat(mine.createdAt) }}</el-descriptions-item>
        </el-descriptions>
    </el-card>
    <div style="display: flex;justify-content: center;align-items: center;margin-top: 10px">
        <el-button @click="updatePassword">修改密码</el-button>
    </div>
    <el-dialog
            title="修改密码"
            draggable
            v-model="showUpdatePassword">
        <el-form :model="form" :rules="rules" ref="form">
            <el-form-item label="旧密码" prop="oldPwd" :label-width="100">
                <el-input v-model="form.oldPwd"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newPwd" :label-width="100">
                <el-input v-model="form.newPwd"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="newPwd2" :label-width="100">
                <el-input v-model="form.newPwd2"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="showUpdatePassword = false">取消</el-button>
        <el-button type="primary" @click="updatePasswordSubmit">提交</el-button>
      </span>
        </template>
    </el-dialog>
</template>
<script>
  import {mapGetters} from 'vuex'
  import moment from 'moment'
  import {commonApi} from '@/api/admin'
  import {ElMessage} from 'element-plus'

  export default {
    data() {
      return {
        form: null,
        rules: null,
        showUpdatePassword: false,
        list: [],
        qiniuData: {
          token: '',
          key: '' // 图片名字处理
        },
      }
    },
    computed: {
      ...mapGetters(['mine']),
    },
    created() {
    },
    methods: {
      dateFormat(time) {
        return moment(time).format('YYYY-MM-DD HH:mm:ss')
      },
      updatePassword() {
        this.showUpdatePassword = true
        this.form = {
          oldPwd: null,
          newPwd: null,
          newPwd2: null
        }
        this.rules = {
          oldPwd: [{
            // validator: () => {
            //
            // },
            min: 5,
            max: 18,
            required: true
          }],
          newPwd: [{
            validator: (rules, val, cb) => {
              if (!val) {
                return cb(new Error('必须填写密码'))
              }
              if (!val.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,18}$/)) {
                return cb(new Error('密码中必须包含字母、数字和特殊字符，且在8~18之间'))
              }
              cb()
            },
            required: true
          }],
          newPwd2: [{
            validator: (rules, val, cb) => {
              if (!val) {
                return cb(new Error('必须填写密码'))
              }
              if (!val.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,18}$/)) {
                return cb(new Error('密码中必须包含字母、数字和特殊字符，且在8~18之间'))
              }
              if (val !== this.form.newPwd) {
                return cb(new Error('两次密码必须一致'))
              }

              cb()
            },
            required: true
          }]
        }
      },
      updatePasswordSubmit() {
        this.$refs.form.validate(async (valid) => {
          if (!valid) {
            return false
          }

          try {
            await commonApi.updatePWd(this.form.oldPwd, this.form.newPwd)
            this.showUpdatePassword = false
            this.form = {}
            ElMessage({
              type: 'success',
              message: '修改成功'
            })
          } catch (e) {
            ElMessage({
              type: 'fail',
              message: e.data.msg
            })
          }
        })
      },

      handleSuccess(props) {
        return async (res, file) => {
          const url = 'http://resources.dramashort01.com/' + res.key
          this.form[props] = url
        }
      }
    }
  }

</script>

<style scoped lang="less">
</style>
