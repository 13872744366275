<template>
  <el-card shadow="never" header="设置" class="m-b-15">
    <div class="flex h vc">
      <div class="f1">共计：{{ list.length }} 个</div>
      <el-button type="primary" @click="handleAdd">添加</el-button>
    </div>
  </el-card>
  <el-table :data="list" stripe border>
    <el-table-column label="id" prop="id" width="100"></el-table-column>
    <el-table-column label="key" prop="key"></el-table-column>
    <el-table-column label="value" prop="value"></el-table-column>
    <el-table-column label="desc" prop="desc"></el-table-column>
    <el-table-column width="140">
      <template #default="{row}">
        <el-button @click="handleEdit(row)" size="small">修改</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-dialog title="添加设置" v-model="showForm">
    <el-form :model="form">
      <el-form-item label="key" :label-width="60">
        <el-input v-model="form.key"></el-input>
      </el-form-item>
      <el-form-item label="value" :label-width="60">
        <el-input v-model="form.value"></el-input>
      </el-form-item>
      <el-form-item label="desc" :label-width="60">
        <el-input v-model="form.desc"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showForm = false">取消</el-button>
        <el-button type="primary" @click="handleSubmit">提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { settingApi } from '@/api/admin'
import { ElMessageBox } from 'element-plus'

export default {
  name: 'SettingListScreen',
  data () {
    return {
      showForm: false,
      form: null,
      list: [],
      types: [
        {
          id: 1,
          title: '价格'
        },
        {
          id: 2,
          title: '获取VIP'
        }
      ]
    }
  },
  async created () {
    this.init()
  },
  methods: {
    async init () {
      const res = await settingApi.list({
        no_page: 1
      })
      this.list = res.data.data.list
    },
    typeFormat (row) {
      const found = this.types.find(type => type.id === row.type)
      if (found) {
        return found.title
      }
      return ''
    },
    handleAdd () {
      this.showForm = true
      this.form = {
        key: null,
        value: null
      }
    },
    handleEdit (d) {
      this.showForm = true
      this.form = JSON.parse(JSON.stringify(d))
    },
    async handleRemove (d) {
      await ElMessageBox.alert('确认删除', '删除', {
        confirmButtonText: '确认',
        callback: async (action) => {
          if (action === 'confirm') {
            await settingApi.remove(d.id)
            await this.init()
          }
        }
      })
    },
    async handleSubmit () {
      if (this.form.id) {
        await settingApi.update(this.form.id, { data: this.form })
      } else {
        await settingApi.create(this.form)
      }
      this.showForm = false
      await this.init()
    }
  }
}
</script>

<style scoped lang="less">

</style>
