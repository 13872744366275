<template>
  <div class="el-menu-vertical-demo">
    <el-menu
        :default-active="active"
        ref="menuSelector"
        router
    >
      <template v-for="(menu,i) in menus" :key="i">
        <el-sub-menu v-if="menu.children" :index="i+''">
          <template #title>
            <span>{{ menu.name }}</span>
          </template>
          <template v-for="(item,j) in menu.children" :key="j">
            <el-sub-menu v-if="item.children" :index="i+'_'+j">
              <template #title>
                <span>{{ item.name }}</span>
              </template>
              <template v-for="(subItem,j) in item.children" :key="j">
                <el-sub-menu v-if="subItem.children" :index="i+'_'+j">
                  <template #title>
                    <span>{{ subItem.name }}</span>
                  </template>
                  <el-menu-item :index="sub2Item.code" v-for="(sub2Item,k) in subItem.children" :key="k">{{
                      sub2Item.name
                    }}
                  </el-menu-item>
                </el-sub-menu>
                <el-menu-item :index="subItem.code" v-else>{{ subItem.name }}</el-menu-item>
              </template>
            </el-sub-menu>
            <el-menu-item :index="item.code" v-else>{{ item.name }}</el-menu-item>
          </template>
        </el-sub-menu>
        <el-menu-item :index="menu.code" v-else>{{ menu.name }}</el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'MenuView',
  data () {
    return {
    }
  },
  computed: {
    ...mapState(['permissions', 'mine']),
    ...mapGetters(['menus']),
    active () {
      return this.$route.path
    }
  }
}
</script>

<style scoped lang="less">
.el-menu-vertical-demo {
  width: 15vw;
  overflow: auto;
  background-color: #fff;
  box-shadow: 0 2px 8px rgb(0 0 0 / 5%);

  .el-menu {
    border: none;
  }
  position: fixed;
  left: 0;
  top: 60px;
  bottom: 0;
  z-index: 10;
}
</style>
