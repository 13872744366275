<template>
  <el-card shadow="never" header="频道管理">
    <div class="flex m-b-15">
      <div class="f1">共计：{{ list.length }} 个</div>
      <el-button type="primary" @click="handleAdd">添加</el-button>
    </div>
    <el-table :data="list">
      <el-table-column label="id" prop="id" width="100"></el-table-column>
      <el-table-column label="标题" prop="title"></el-table-column>
      <el-table-column label="ui长相" prop="type"></el-table-column>
      <el-table-column label="位置" prop="position"></el-table-column>
      <el-table-column label="状态" prop="status">
        <template #default="{row}">
          {{statusFormat(row)}}
        </template>
      </el-table-column>
      <el-table-column label="权重" prop="weight"></el-table-column>
      <el-table-column width="200">
        <template #default="{row}">
          <el-button @click="toDetail(row)" size="small" type="primary">详情</el-button>
          <el-button @click="handleEdit(row)" size="small">修改</el-button>
          <el-button @click="handleRemove(row)" type="danger" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <el-dialog title="添加频道" v-model="showForm">
    <el-form :model="form">
      <el-form-item label="标题" :label-width="60">
        <el-input v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="ui长相" :label-width="60">
        <el-input v-model="form.type"></el-input>
      </el-form-item>
      <el-form-item label="位置" :label-width="60">
        <el-input v-model="form.position"></el-input>
      </el-form-item>
      <el-form-item label="状态" :label-width="60">
        <el-input v-model="form.status"></el-input>
      </el-form-item>
      <el-form-item label="权重" :label-width="60">
        <el-input v-model="form.weight"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showForm = false">取消</el-button>
        <el-button type="primary" @click="handleSubmit">提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { shortChannelApi } from '@/api/admin'
import { ElMessageBox } from 'element-plus'

export default {
  name: 'ShortChannelListScreen',
  data () {
    return {
      showForm: false,
      form: null,
      list: [],
      qiniuData: {
        token: '',
        key: '' // 图片名字处理
      },
      statusArr: [
        {
          val: 0,
          label: '隐藏'
        },
        {
          val: 1,
          label: '可用'
        }
      ]
    }
  },
  watch: {
    '$route.params' (newVal) {
      console.log('newVal', newVal)
      if (newVal) {
        this.init()
      }
    }
  },
  async created () {
    this.init()
  },
  methods: {
    async init () {
      const res = await shortChannelApi.list({
        no_page: 1
      })
      this.list = res.data.data.list
    },
    toDetail (d) {
      this.$router.push({
        name: 'short_channel_record_list',
        params: {
          id: d.id
        }
      })
    },
    handleAdd () {
      this.showForm = 'add'
      this.form = {
        key: null,
        value: null
      }
    },
    handleEdit (d) {
      this.showForm = 'edit'
      this.form = JSON.parse(JSON.stringify(d))
    },
    async handleRemove (d) {
      await ElMessageBox.alert('确认删除', '删除', {
        confirmButtonText: '确认',
        callback: async (action) => {
          if (action === 'confirm') {
            await shortChannelApi.remove(d.id)
            await this.init()
          }
        }
      })
    },
    async handleSubmit () {
      if (this.showForm === 'edit') {
        await shortChannelApi.update(this.form.id, { data: this.form })
      } else {
        await shortChannelApi.create(this.form)
      }
      this.showForm = null
      await this.init()
    },
    statusFormat (row) {
      return this.statusArr.find(item => item.val === row.status).label
    },
  }
}
</script>

<style scoped lang="less">
.cover {
  width: 200px;
  height: 100px;
}
</style>
