<template>
  <div id="global-header">
    <div class="logo">{{ config.title }}</div>
    <div class="f1 flex">
      <HeaderMenuView v-if="!config.title"></HeaderMenuView>
    </div>
    <AvatarDrop></AvatarDrop>
  </div>
</template>

<script>
import config from '@/config'
import AvatarDrop from '@/components/AvatarDrop'
import HeaderMenuView from './HeaderMenuView.vue'
import { mapState } from 'vuex'

export default {
  name: 'HeaderView',
  components: {
    AvatarDrop,
    HeaderMenuView
  },
  data () {
    return {
      config,
      selectDyFanzhishiMinProgramId: null
    }
  },
  computed: {
    ...mapState(['mine'])
  }
}
</script>

<style scoped lang="less">
#global-header {
  display: flex;
  line-height: 60px;

  .logo {
    font-size: 20px;
    font-weight: 500;
    margin-right: 40px;
    padding: 0 20px;
    color: #fff;
    background-color: var(--el-color-primary);
  }

  .header-btn {
    line-height: 60px;
    height: 57px;
    cursor: pointer;
    padding: 0 20px;
    position: relative;

    &:hover {
      border-bottom: 3px solid var(--el-color-primary);
      color: var(--el-color-primary);
      font-weight: 400;
    }

    &.active {
      border-bottom: 3px solid var(--el-color-primary);
      color: var(--el-color-primary);
      font-weight: 400;
    }
  }
}
</style>
